import React from 'react';
import { BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import About from './pages/About';
import Contacts from './pages/Contacts';
import Works from './pages/Works';
import page404 from './pages/404';
import ReactGA from 'react-ga';

function App() {
    return (
        <Router>
            <Switch>
                <Route
                    exact
                    path={ `${ process.env.PUBLIC_URL + "/" }` }
                    component={ About }
                />
                <Route
                    exact
                    path={ `${ process.env.PUBLIC_URL + "/contact" }` }
                    component={ Contacts }
                />
                <Route
                    exact
                    path={ `${ process.env.PUBLIC_URL + "/works" }` }
                    component={ Works }
                />
                <Route exact component={ page404 }/>
            </Switch>
        </Router>
    );
}
ReactGA.initialize('UA-174930512-1');
export default App;
