import React from 'react';
import StacksData from '../../data/stacks/stacks';

const Stacks = () => {
    return (
        <div id="stacks" className="block spacer p-top-xl">
            <div className="spacer">
                <h4>Management skills and tools<span className="dot">.</span></h4>
                <div className="row gutter-width-lg with-pb-lg">
                    { StacksData[0].management && StacksData[0].management.map( ( item, key ) => {
                        return (
                            <div key={ key } className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                                <div className="card">
                                    <div className="card-body">
                                        <h5 className="text-primary">{ item.title }</h5>
                                        <ul className="mt-0 list-unstyled">
                                            {item.items.map( ( item, key ) => (
                                                <React.Fragment key={ key }>
                                                    <li>{ item }</li>
                                                </React.Fragment>
                                            ) ) }
                                    </ul>
                                    </div>
                                </div>
                            </div>
                        );
                    } ) }
                </div>
            </div>

            <div className="block spacer mt-4">
                <h4>Currently tech stack<span className="dot">.</span></h4>
                <div className="row gutter-width-lg with-pb-lg">
                    { StacksData[0].tech && StacksData[0].tech.map( ( item, key ) => {
                        return (
                            <div key={ key } className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                                <div className="card">
                                    <div className="card-body">
                                        <h5 className="text-primary">{ item.title }</h5>
                                        <ul className="mt-0 list-unstyled">
                                            {item.items.map( ( item, key ) => (
                                                <React.Fragment key={ key }>
                                                    <li>{ item }</li>
                                                </React.Fragment>
                                            ) ) }
                                    </ul>
                                    </div>
                                </div>
                            </div>
                        );
                    } ) }
                </div>
            </div>
        </div>
    );
};

export default Stacks;
