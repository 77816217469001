import React from 'react';

const PageTitleContacts = () => {
    return (
        <section id="page-title">
            <div className="wrapper">
                <div className="title position-relative noselect">
                    <h1 className="c">Drop a line<span className="dot">.</span></h1>
                    <div className="title-clone">Drop a line.</div>
                </div>
            </div>
        </section>
    );
};

export default PageTitleContacts;
