import React, { Fragment } from 'react';
import MetaTags from 'react-meta-tags';

import Loading from '../blocks/loading/Loading';
import Header from '../blocks/header/Header';
import Footer from '../blocks/footer/Footer';

import PageTitleWorks from '../blocks/page-title/PageTitleWorks';
import Gallery from '../blocks/gallery/Gallery';

import meta from '../data/general/meta';
import ReactGA from 'react-ga';

const Works = () => {
    document.body.classList.add( 'archive' );
    ReactGA.pageview('/works');

    return (
        <Fragment>
            <MetaTags>
                <meta charSet="UTF-8"/>
                <title>{meta.title}</title>

                <meta httpEquiv="x-ua-compatible" content="ie=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="description" content={meta.description} />
                <meta name="keywords" content={meta.keywords} />
                <meta name="robots" content="index, follow, noodp" />
                <meta name="googlebot" content="index, follow" />
                <meta name="google" content="notranslate" />
                <meta name="format-detection" content="telephone=no" />
            </MetaTags>

            <Loading />

            <Header />

            <main id="main" className="site-main noselect">
                <PageTitleWorks />

                <section id="page-content" className="spacer p-top-xl">
                    <Gallery paddingBottomClass=" pb-0"/>
                </section>
            </main>

            <Footer pos={'relative'}/>
        </Fragment>
    );
};

export default Works;
