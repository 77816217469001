import React from 'react';

const AboutContent = () => {
    return (
        <div id="about" className="block noselect">
            <h2>
                I’m <del>Valério Oliveira</del> <span className="dot">Valck</span>
            </h2>

            <div className="row bg-half-ring-left gutter-width-lg">
                <div className="col align-self-top pl-0">
                    <div className="img object-fit">
                        <div className="object-fit-cover ">
                            <img src="assets/img/me.png" alt="it's me, valck!" className="card img-fluid" />
                        </div>
                    </div>
                </div>
                <div className="col align-self-center description noselect">
                    <h4>Creative Technologist<span className="dot">.</span></h4>
                    <p>Based in São Caetano do Sul / São Paulo, currently full-time Technology Director at <a title="Dentsu Brazil" href="https://www.dentsu.com" target="_blank" rel="noopener noreferrer">Dentsu Brazil</a>.</p>
                    <p>Former founder of <a title="Slikland Creative Development" href="https://thefwa.com/profiles/slikland" target="_blank" rel="noopener noreferrer">Slikland</a>, a creative development studio specialized in digital craft development and cutting-end experiences for the most renowned brands in the world.</p>
                    <p>More than 20 years of experience with software development with a strong background in UX, currently focused on technical management of digital products.</p>
                    <p>For more information, visit my <a title="LinkedIn profile" href="http://www.linkedin.com/in/valck/" target="_blank" rel="noopener noreferrer">LinkedIn profile</a>.</p>
                    <p>Not available for freelance at this time.</p>
                </div>
            </div>
        </div>
    );
};

export default AboutContent;
