import React from 'react';

const Copyright = () => {
    return (
        <div className="copyright">
            <p className="btn btn-link text-dark border-0 p-0" >© Copyright {new Date().getFullYear()} Valck. All rights reserved.</p>
        </div>
    );
};

export default Copyright;
