import React from 'react';


const Header = () => {
    return (
        <header id="header" className="site-header sticky-top">
            {/* <div className="wrapper d-flex justify-content-between">
                <div className="align-self-center">
                    <Logo image={ "/assets/img/logo/logo-2.svg" } />
                </div>
                <div className="menu">
                    <nav className="menu-primary">
                        <ul className="clearfix list-unstyled">
                            <li className={ "menu-item" + ( window.location.pathname === '/' ? "btn btn-link current-menu-item" : "" ) }>
                                <a title="About" href={ process.env.PUBLIC_URL + "/" }>About</a>
                            </li>

                            <li className={ "menu-item" + ( window.location.pathname === '/works' ? "btn btn-link current-menu-item" : "" ) }>
                                <a title="Works" href={ process.env.PUBLIC_URL + "/works" }>Works</a>
                            </li>

                            <li className={ "menu-item" + ( window.location.pathname === '/contact' ? "btn btn-link current-menu-item" : "" ) }>
                                <a title="Contact" href={ process.env.PUBLIC_URL + "/contact" }>Contact</a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div> */}
        </header>
    );
};

export default Header;
