import React from 'react';
import AwardsData from '../../data/awards/awards';

const Awards = () => {
    function sortResults(arr, prop, asc) {
        arr.sort(function(a, b) {
            if (asc) {
                return (a[prop] > b[prop]) ? 1 : ((a[prop] < b[prop]) ? -1 : 0);
            } else {
                return (b[prop] > a[prop]) ? 1 : ((b[prop] < a[prop]) ? -1 : 0);
            }
        });
    }
    return (
        <div id="awards" className="block spacer p-top-xl">
            <h4>Awards I’ve earned<span className="dot">.</span></h4>
            <div className="row gutter-width-lg with-pb-lg">
                {sortResults(AwardsData, 'title', true)}
                { AwardsData && AwardsData.map( ( item, key ) => {
                    return (
                        <div key={ key } className="col-xl-3 col-lg-3 col-md-4 col-sm-12 col-12">
                            <div className="card border-0">
                                <div className="card-body p-0">
                                    <h5 className="text-primary">{ item.title }</h5>
                                    {
                                        sortResults(item.awards, 'date', false)
                                    }
                                    {item.awards.map( ( item, key ) => (
                                            <React.Fragment key={ key }>
                                                <p className="text-muted bold">{ item.date }</p>
                                                <ul className="mt-0 list-unstyled">
                                                {item.projects.map( ( item, key ) => (
                                                    <React.Fragment key={ key }>
                                                        <li className="btn-link">{ item }</li>
                                                    </React.Fragment>
                                                ) ) }
                                                </ul>
                                            </React.Fragment>
                                        ) ) }
                                </div>
                            </div>
                        </div>
                    );
                } ) }
            </div>
        </div>
    );
};

export default Awards;
