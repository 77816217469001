import React, { Fragment } from 'react';
import MetaTags from 'react-meta-tags';

import Loading from '../blocks/loading/Loading';
import Header from '../blocks/header/Header';
import Footer from '../blocks/footer/Footer';

import PageTitleContacts from '../blocks/page-title/PageTitleContacts';

import meta from '../data/general/meta';
import ReactGA from 'react-ga';

const Contacts = () => {
    document.body.classList.add( 'page' );
    ReactGA.pageview('/contact');

    return (
        <Fragment>
            <MetaTags>
                <meta charSet="UTF-8" />
                <title>{meta.title}</title>

                <meta httpEquiv="x-ua-compatible" content="ie=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="description" content={meta.description} />
                <meta name="keywords" content={meta.keywords} />
                <meta name="robots" content="index, follow, noodp" />
                <meta name="googlebot" content="index, follow" />
                <meta name="google" content="notranslate" />
                <meta name="format-detection" content="telephone=no" />
            </MetaTags>

            <Loading />

            <Header/>

            <main id="main" className="site-main">
                <PageTitleContacts />

                <section id="page-content" className="block spacer p-top-xl">
                    <div className="wrapper">
                        <div id="contacts" className="block">
                            <div className="row">
                                <div className="col-xl-4 col-lg-4 col-md-4 col-12 noselect">
                                    <h5>Like my stuff ?</h5>
                                    <p className="mt-0">Sorry, I'm not available for freelance at this time.</p>
                                </div>

                                <div className="col-xl-8 col-lg-8 col-md-8 col-12">
                                    <div className="list-group list-group-horizontal-sm">
                                        <div className="list-group-item">
                                            <h5 className="noselect text-primary">Email</h5>
                                            <p><a title="hello@valck.in" href="mailto:hello@valck.in?subject=Contact from portfolio">hello@valck.in</a></p>
                                        </div>
                                        <div className="list-group-item">
                                            <h5 className="noselect text-primary">LinkedIn</h5>
                                            <p><a title="linkedin.com/in/valck" href="https://www.linkedin.com/in/valck/" target="_blank" rel="noopener noreferrer">linkedin.com/in/valck</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>

            <Footer />
        </Fragment>
    );
};

export default Contacts;
